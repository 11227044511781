//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
import { withRouter } from 'react-router-dom';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import styles from './index.scss';
//------------------------------------------------------------------------------
// My Components ---------------------------------------------------------------
import { Modal, Button, ButtonStyle } from '@cmp/common';
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class GenericModal extends React.Component {
  render() {
    const {
      history,
      location,
      title,
      subtitle,
      action,
      to,
      onClick,
      onClose,
      actionStyle,
    } = this.props;
    const componentClasses = classNames(styles.GenericModal);
    const buttonStyle = actionStyle ? actionStyle : ButtonStyle.Secondary;

    return (
      <Modal.Base
        className={componentClasses}
        onClose={onClose || (() => history.push(location.state.from))}
      >
        <h2 className={styles.Title}>{title}</h2>
        <span className={styles.Subtitle}>{subtitle}</span>
        {action && (
          <Button style={actionStyle} to={to} onClick={onClick}>
            {action}
          </Button>
        )}
      </Modal.Base>
    );
  }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default withRouter(GenericModal);
