//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
import { Helmet } from 'react-helmet';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import styles from './index.scss';
//------------------------------------------------------------------------------
// My Components ---------------------------------------------------------------
import {
  Breadcrumbs,
  Slideshow,
  Button,
  ButtonStyle,
  AddItem as AddItemComponent,
} from '@cmp/common';
//------------------------------------------------------------------------------
// Assets ----------------------------------------------------------------------
import { ChevronUpFilled } from '@cmp/images';
//------------------------------------------------------------------------------
// API -------------------------------------------------------------------------
import {
  Detail as ProductDetailRequest,
  Metafields as ProductMetafieldsRequest,
} from '@api/endpoints/get/products';
import { addItem } from '@helpers/referrals';
//------------------------------------------------------------------------------
// Helpers & Constants ---------------------------------------------------------
import { shopifyFormatter } from '@helpers/formatter';
import { getTargetUrl } from '@helpers/dom';
import { CurrencyPrefix } from '@helpers/constants/currency';
import {
  MetafieldsKeys,
  MetafieldsReadableKeys,
} from '@helpers/constants/products';
//------------------------------------------------------------------------------
// Classes ---------------------------------------------------------------------
import ExternalVideo from '@classes/externalVideo';
import { withTranslation } from 'react-i18next';
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class Product extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: '',
      expandedSections: [],
      productVideos: [],
    };
  }

  componentDidMount() {
    const { history, match: { params: { id } = {} } = {} } = this.props;
    if (!id) return history.replace('/');

    this.setState({ id });
  }

  componentDidUpdate(prevProps) {
    const { match: { params: { id } = {} } = {} } = this.props;
    const { id: currentId } = this.state;

    if (id !== currentId) {
      this.setState({ id }, this.refetch);
    }
  }

  productInformationComponent(product) {
    const { t } = this.props;
    const price =
      shopifyFormatter.product(product).fullPrice(CurrencyPrefix.USD) ||
      '$0.00';

    return (
      <div className={styles.Information}>
        <div className={styles.InformationHeader}>
          <h2 className={styles.Title}>{product.title}</h2>
          <span
            className={styles.Subtitle}
            dangerouslySetInnerHTML={{ __html: product.body_html }}
          ></span>
        </div>
        <div className={styles.Pricing}>
          <div className={styles.Value}>
            <span className={styles.Amount}>{price}</span>
          </div>
        </div>
        <AddItemComponent product={product} className={styles.AddItem} />
      </div>
    );
  }

  setupVideos(product) {
    const formattedProduct = shopifyFormatter.product(product);

    // Parse all videos
    const allVideos = formattedProduct.parsedVideos();
    const productVideos = allVideos
      ? allVideos.map((x) => new ExternalVideo(x))
      : [];

    // Request their thumbnails if needed, and update state (so the slideshow
    // refreshes correctly)
    const thumbnailRequests = productVideos.map((x) =>
      x.loadThumbnailIfNeeded()
    );
    Promise.all(thumbnailRequests).then((res) =>
      this.setState({ productVideos })
    );

    this.setState({ productVideos });
  }

  detailsComponent(product) {
    const formattedProduct = shopifyFormatter.product(product);
    const { productVideos } = this.state;

    return (
      <div className={styles.Details}>
        <Slideshow
          videos={productVideos}
          images={formattedProduct.images()}
          className={styles.Slideshow}
          alt={product.title}
        />
        {this.productInformationComponent(product)}
      </div>
    );
  }

  render() {
    const { t } = this.props;
    const { id } = this.state;
    const componentClasses = classNames(styles.Product);

    return (
      <ProductDetailRequest
        pathParams={{ id }}
        skip={!id}
        onComplete={({ product }) => this.setupVideos(product)}
      >
        {({ data, refetch }) => {
          const { product } = data || {};
          if (!product) return '';

          this.refetch = refetch;

          return (
            <div className={componentClasses}>
              <Helmet>
                <title>{product.title}</title>
              </Helmet>

              <Breadcrumbs
                pages={[
                  { title: t('dashboard_AllProducts'), url: '/products' },
                  { title: product.title, url: `/product/${product.id}` },
                ]}
                className={styles.Breadcrumbs}
              />
              {this.detailsComponent(product)}
            </div>
          );
        }}
      </ProductDetailRequest>
    );
  }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default withTranslation()(Product);
