//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import styles from './index.scss';
//------------------------------------------------------------------------------
// My Components ---------------------------------------------------------------

//------------------------------------------------------------------------------
// Assets ----------------------------------------------------------------------
import { MinusIcon, PlusIcon } from '@cmp/images';
//------------------------------------------------------------------------------
// Helpers & Constants ---------------------------------------------------------
import { shopifyFormatter, currencyFormatter } from '@helpers/formatter';
import { CurrencyPrefix } from '@helpers/constants/currency';
import { withTranslation } from 'react-i18next';
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class Products extends React.Component {
  isEditingProduct(product) {
    const { editingProducts } = this.props;
    return (
      editingProducts &&
      editingProducts.findIndex((x) => x.id === product.id) > -1
    );
  }

  stepperComponent(product, disabled) {
    const { onEditProduct, editingProducts } = this.props;
    const { quantityRecommended } = product;

    const componentClasses = classNames(styles.Stepper, {
      [styles.Disabled]: disabled,
    });

    return (
      <div className={componentClasses}>
        <PlusIcon
          className={styles.Icon}
          tabIndex={0}
          onClick={() =>
            !disabled && onEditProduct(product, quantityRecommended + 1)
          }
          onKeyDown={(e) => {
            if (e.key === 'Enter')
              !disabled && onEditProduct(product, quantityRecommended + 1);
          }}
        />
        <span className={styles.Amount}>{quantityRecommended}</span>
        <MinusIcon
          className={styles.Icon}
          tabIndex={0}
          onClick={() =>
            !disabled && onEditProduct(product, quantityRecommended - 1)
          }
          onKeyDown={(e) => {
            if (e.key === 'Enter')
              !disabled && onEditProduct(product, quantityRecommended - 1);
          }}
        />
      </div>
    );
  }

  quantityComponent(
    quantityPurchased,
    quantityRecommended,
    editable,
    disabled
  ) {
    if (editable) return '';
    const quanity = Math.abs(quantityPurchased - quantityRecommended);
    if (disabled) {
      return <span className={styles.Amount}>{quanity}x</span>;
    } else {
      return <span className={styles.Amount}>{quantityPurchased}x</span>;
    }
  }

  /**
   * This function first selects a function to run based on params
   * Then runs it
   */
  eventHandler(e, isEditing, onEditProduct, product) {
    let handlerFn = () => {}; // noop
    const enterKeyPressedOrClicked = e.type === 'click' || e.key === 'Enter';
    if (!isEditing && enterKeyPressedOrClicked)
      handlerFn = () => onEditProduct(product, 0);
    handlerFn();
  }

  singleProductComponent(product, editable, disabled) {
    const { onEditProduct, t, i18n } = this.props;
    const {
      id,
      productName,
      quantityPurchased,
      editingProducts,
      quantityRecommended,
      fullPrice,
      discountedPrice,
    } = product;

    const formattedProduct = shopifyFormatter.product(product);
    const defaultImage = formattedProduct.defaultImage(i18n);
    const isEditing = this.isEditingProduct(product);

    const componentClasses = classNames(styles.SingleProduct, {
      [styles.Disabled]: disabled,
    });

    const removeClasses = classNames(styles.Remove, {
      [styles.Disabled]: isEditing,
    });

    const trimmedFullPrice = currencyFormatter.trimmedPrice(fullPrice);
    const trimmedDiscountedPrice = currencyFormatter.trimmedPrice(
      discountedPrice
    );
    const hasDiscount =
      trimmedDiscountedPrice && trimmedFullPrice !== trimmedDiscountedPrice;

    return (
      <div className={componentClasses} key={id}>
        <img src={defaultImage && defaultImage.src} className={styles.Image} />
        <div className={styles.Information}>
          <Link
            to={`/product/${product.shopifyProductId}`}
            className={styles.Title}
          >
            {productName}
          </Link>
          <div className={styles.Detailed}>
            {this.quantityComponent(
              quantityPurchased,
              quantityRecommended,
              editable,
              disabled
            )}
            {trimmedDiscountedPrice && (
              <span className={styles.Price}>
                {currencyFormatter.priceWithCurrency(
                  trimmedDiscountedPrice,
                  CurrencyPrefix.USD
                )}
              </span>
            )}
            {hasDiscount && (
              <span className={styles.OriginalPrice}>
                {currencyFormatter.priceWithCurrency(
                  trimmedFullPrice,
                  CurrencyPrefix.USD
                )}
              </span>
            )}
          </div>
          {editable && (
            <span
              className={removeClasses}
              tabIndex={0}
              onClick={(e) =>
                this.eventHandler(e, isEditing, onEditProduct, product)
              }
              onKeyDown={(e) =>
                this.eventHandler(e, isEditing, onEditProduct, product)
              }
            >
              {t('dashboard_Remove')}
            </span>
          )}
        </div>
        {editable && this.stepperComponent(product, isEditing)}
      </div>
    );
  }

  render() {
    const { products, editable, disabled, className } = this.props;
    if (!products) return '';

    const componentClasses = classNames(styles.Products, {
      [className]: className,
    });

    return (
      <div className={styles.Products}>
        {products.map((product) =>
          this.singleProductComponent(product, editable, disabled)
        )}
      </div>
    );
  }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default withTranslation()(Products);
