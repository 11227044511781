//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
import validator from 'validator';
import { Helmet } from 'react-helmet';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import styles from './index.scss';
import clientStyles from '@style/common/client.scss';
//------------------------------------------------------------------------------
// My Components ---------------------------------------------------------------
import { Modal, Badge, Button, ButtonStyle, Input } from '@cmp/common';
//------------------------------------------------------------------------------
// Classes ---------------------------------------------------------------------
import FormValidator from '@classes/formValidator';
//------------------------------------------------------------------------------
// Helpers ---------------------------------------------------------------------
import { withReferral, sendReferral, clearReferral } from '@helpers/referrals';
import { responseFormatter } from '@helpers/formatter';
import { isNameValid } from '@helpers/validation';
import { withTranslation } from 'react-i18next';
import { t } from 'i18next';
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class Patient extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      email: '',
      phoneNumber: '',
      loading: false,
      errorMessage: null,
    };
    this.onSendClick = this.onSendClick.bind(this);
    this.setupValidator();
  }

  componentDidMount() {
    this.setupData();
  }

  setupData() {
    const { location: { state } = {} } = this.props;

    const { fromReferral } = state || {};
    if (!fromReferral) return;

    const {
      consumerEmail: email = '',
      consumerName: name = '',
      consumerPhone: phoneNumber = '',
    } = fromReferral;
    this.setState({ email: email || '', name, phoneNumber: phoneNumber || '' });
  }

  setupValidator() {
    // to-do: create exclusivity rules in FormValidator
    let emailValidationMethod = (string) => validator.isEmail(string);
    let phoneNumberValidationMethod = (string) =>
      validator.isMobilePhone(string, 'en-US');

    const items = [
      {
        key: 'name',
        defaultValue: '',
        method: (string) => isNameValid(string),
        validWhen: true,
      },
      {
        key: 'email',
        defaultValue: '',
        method: (string, data) => {
          if (!data['phoneNumber'] || !data['email']) {
            return (
              emailValidationMethod(string) ||
              phoneNumberValidationMethod(data['phoneNumber'])
            );
          }

          return (
            emailValidationMethod(string) &&
            phoneNumberValidationMethod(data['phoneNumber'])
          );
        },
        validWhen: true,
      },
      {
        key: 'phoneNumber',
        defaultValue: '',
        method: (string, data) => {
          if (!data['phoneNumber'] || !data['email']) {
            return (
              phoneNumberValidationMethod(string) ||
              emailValidationMethod(data['email'])
            );
          }

          return (
            phoneNumberValidationMethod(string) &&
            emailValidationMethod(data['email'])
          );
        },
        validWhen: true,
      },
    ];

    this.validator = new FormValidator(items);
  }

  headerComponent() {
    const { t } = this.props;
    return (
      <div className={styles.Header}>
        <h2 className={styles.Title}>{t('dashboard_PatientDetails')}</h2>
        <span className={styles.Subtitle}>
          {t('dashboard_PatientDetailsSubtitle')}
        </span>
      </div>
    );
  }

  onSendClick() {
    let that = this;
    const { name, email, phoneNumber, loading, errorMessage } = this.state;
    const { history, location, __referral } = this.props;
    const data = { name, email, phoneNumber };
    const fromReferral = location.state.fromReferral;

    this.setState({ loading: true });

    sendReferral(data, fromReferral)
      .then(() => {
        if (!fromReferral) {
          clearReferral();
        }

        history.push({
          pathname: '/recommendation/success',
          state: {
            user: data,
            referral: fromReferral || __referral,
            from: location.state.from,
            modal: true,
          },
        });
      })
      .catch((err) =>
        that.setState({
          errorMessage: responseFormatter.error(err).message(),
        })
      )
      .finally(() => that.setState({ loading: false }));
  }

  formComponent() {
    let that = this;
    const { t } = this.props;
    const { name, email, phoneNumber, loading, errorMessage } = this.state;
    const { history, location } = this.props;

    const validateAll = this.validator.validateAll(this.state);
    const nameTooLong = name.length > 64

    return (
      <div className={styles.Form}>
        <Input
          title={t('dashboard_PatientName')}
          placeholder={t('dashboard_PatientNamePH')}
          className={styles.Input}
          value={name}
          onChange={(name) => this.setState({ name })}
          shouldAutoFocus={true}
        />
        { nameTooLong ?
          <span className={styles.AuxiliarError}>
            {t('dashboard_PatientNameAuxLongName')}
          </span>
          :
          <span className={styles.Auxiliar}>
            {t('dashboard_PatientNameAux')}
          </span>
        }
        <Input
          title={t('dashboard_Email')}
          placeholder={t('dashboard_EmailPH')}
          className={styles.Input}
          value={email}
          onChange={(email) => this.setState({ email })}
        />
        <Button
          style={ButtonStyle.Primary}
          className={styles.Button}
          onClick={this.onSendClick}
          loading={loading}
          disabled={!validateAll.isValid}
        >
          {t('dashboard_SendRecommendation')}
        </Button>
        {errorMessage && (
          <span className={clientStyles.ErrorMessage}>{errorMessage}</span>
        )}
        <span className={styles.Disclosure}>
          {t('dashboard_ConfirmingConsent')}
        </span>
      </div>
    );
  }

  render() {
    const { history, location, t } = this.props;
    const componentClasses = classNames(styles.Patient);

    return (
      <Modal.Base
        className={componentClasses}
        onClose={() => history.push(location.state.from)}
      >
        <Helmet>
          <title>{t('dashboard_PatientDetails')}</title>
        </Helmet>

        {this.headerComponent()}
        {this.formComponent()}
      </Modal.Base>
    );
  }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default withReferral(withTranslation()(Patient));
