//------------------------------------------------------------------------------
// Node Modules ----------------------------------------------------------------
import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
//------------------------------------------------------------------------------
// Style -----------------------------------------------------------------------
import styles from './index.scss';
//------------------------------------------------------------------------------
// My Components ---------------------------------------------------------------
import { Header, Footer } from '@cmp/common';
import {
  Banner,
  CreateAccount,
  ExclusiveAccess,
  PatientSatisfaction,
  Testimonials,
  HowItWorks,
  InfluencerVideo,
} from './components';
//------------------------------------------------------------------------------
// Helpers ---------------------------------------------------------------------
import { ScreenSet, showScreenSet } from '@helpers/gigya';
//------------------------------------------------------------------------------
// React Class -----------------------------------------------------------------
class Landing extends React.Component {
  componentDidMount() {
    showScreenSet(ScreenSet.SignIn, 'login-container');
  }

  render() {
    const componentClasses = classNames(styles.Landing);

    return (
      <div className={componentClasses}>
        <Header />
        <div className={styles.Content}>
          <Banner />
          <HowItWorks />
          {/*<InfluencerVideo />*/}
          <PatientSatisfaction />
          <ExclusiveAccess />
          {/*<Testimonials />*/}
          <CreateAccount />
        </div>
        <Footer />
      </div>
    );
  }
}
//------------------------------------------------------------------------------
// Export ----------------------------------------------------------------------
export default Landing;
